<template>
  <div>
    <!-- BREADCRUMB -->
    <section
      class="breadcrumb-bg"
      style="background-image: url(../assets/img/background/page-title-bg-img.jpg)"
    >
      <div class="container">
        <div class="breadcrumb-holder">
          <div>
            <h1 class="breadcrumb-title">主題區</h1>
            <ul class="breadcrumb breadcrumb-transparent">
              <li class="breadcrumb-item">
                <a class="text-white" href="/">Home</a>
              </li>
              <li class="breadcrumb-item text-white active" aria-current="page">
                {{ article.category }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <!-- content -->
    <section class="py-6 py-md-8">
      <div class="container">
        <div class="card">
          <div class="card-body border-top-5 px-3 rounded border-warning">
            <h3 class="card-title text-warning mb-5">{{ article.title }}</h3>
            <ul class="list-unstyled d-flex mb-5">
              <li class="">
                <a class="text-muted d-inline-block mr-3" href="#author"><i class="fa fa-user mr-2" aria-hidden="true"></i>{{ article.author }}</a>
              </li>
              <li class="mr-3">
                <a class="text-muted d-inline-block" href="#post-time"><i class="fa fa-clock-o mr-2" aria-hidden="true"></i>{{ article.published_at }}</a>
              </li>
              <li class="mx-2">
                <FBShareButton />
              </li>
              <li class="mx-2">
                <LineShareButton />
              </li>
            </ul>
            <div v-if="article.banner" class="mb-6 position-relative">
              <img class="card-img-top" :src="article.banner" alt="Card image cap">
            </div>
            <div v-else-if="article.videoCode" class="embed-responsive embed-responsive-16by9 mb-6">
              <iframe class="embed-responsive-item" :src="`https://www.youtube.com/embed/${article.videoCode}`" allowfullscreen></iframe>
            </div>
            <p id="article-content" class="card-text text-justify mb-6" v-html="article.content"></p>
          </div>
        </div>
        <section class="pt-9 py-md-7">
          <div class="container">
            <div class="section-title justify-content-center mb-8 wow fadeInUp">
              <span class="shape shape-left bg-info"></span>
              <h2 class="text-danger">來～我們一起玩遊戲！</h2>
              <span class="shape shape-right bg-info"></span>
            </div>
            <div class="row wow fadeInUp">
              <div class="col-sm-3 col-xs-12">
                <a href="#" @click.prevent="checkGradeIsAvaliable($event, 1, '/fqgame/kindergarten')">
                  <div class="card bg-success card-hover scrolling">
                    <div class="card-body text-center p-0">
                      <div class="card-icon-border-large border-success text-success pt-6">
                        <i class="fa fa-leaf font-size-48" aria-hidden="true"></i>
                      </div>
                      <h2 class="text-white font-size-28 pt-1 pt-lg-5 pb-2 pb-lg-6 mb-0 font-dosis">理財幼幼級</h2>
                      <span class="btn-scroll-down d-block pb-4 pb-lg-5">
                        <i class="fa fa-chevron-down" aria-hidden="true"></i>
                      </span>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col-sm-3 col-xs-12">
                <a href="#" @click.prevent="checkGradeIsAvaliable($event, 2, '/fqgame/elementary')">
                  <div class="card bg-info card-hover scrolling">
                    <div class="card-body text-center p-0">
                      <div class="card-icon-border-large border-info text-info pt-6">
                        <i class="fa fa-futbol-o font-size-48" aria-hidden="true"></i>
                      </div>
                      <h2 class="text-white font-size-28 pt-1 pt-lg-5 pb-2 pb-lg-6 mb-0 font-dosis">理財國小級</h2>
                      <span class="btn-scroll-down d-block pb-4 pb-lg-5">
                        <i class="fa fa-chevron-down" aria-hidden="true"></i>
                      </span>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col-sm-3 col-xs-12">
                <a href="#" @click.prevent="checkGradeIsAvaliable($event, 3, '/fqgame/junior')">
                  <div class="card bg-danger card-hover scrolling">
                    <div class="card-body text-center p-0">
                      <div class="card-icon-border-large border-danger text-danger pt-6">
                        <i class="fa fa-gamepad font-size-48" aria-hidden="true"></i>
                      </div>
                      <h2 class="text-white font-size-28 pt-1 pt-lg-5 pb-2 pb-lg-6 mb-0 font-dosis">理財國中級</h2>
                      <span class="btn-scroll-down d-block pb-4 pb-lg-5">
                        <i class="fa fa-chevron-down" aria-hidden="true"></i>
                      </span>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col-sm-3 col-xs-12">
                <a href="#" @click.prevent="checkGradeIsAvaliable($event, 4, '/fqgame/senior')">
                  <div class="card bg-warning card-hover scrolling">
                    <div class="card-body text-center p-0">
                      <div class="card-icon-border-large border-warning text-warning pt-6">
                        <i class="fa fa-graduation-cap font-size-48" aria-hidden="true"></i>
                      </div>
                      <h2 class="text-white font-size-28 pt-1 pt-lg-5 pb-2 pb-lg-6 mb-0 font-dosis">理財高中級</h2>
                      <span class="btn-scroll-down d-block pb-4 pb-lg-5">
                        <i class="fa fa-chevron-down" aria-hidden="true"></i>
                      </span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  </div>
</template>

<script>
import tokenGenerator from '@/tokenGenerator.js'
import FBShareButton from '@/components/FBShareButton.vue'
import LineShareButton from '@/components/LineShareButton.vue'
export default {
  name: 'article',
  components: { FBShareButton, LineShareButton },
  data () {
    return {
      article: {}
    }
  },
  created () {
    this.getData()
  },
  mounted () {
    require('@/assets/js/base.js')
  },
  methods: {
    getData () {
      const vm = this
      const api = `${process.env.VUE_APP_APIPATH}/iPlay/article/detail`
      const id = vm.$route.params.id

      vm.axios.post(api, {
        token: tokenGenerator(),
        id: id
      })
        .then((res) => {
          vm.article = res.data.content
          vm.$nextTick(() => {
            const contentLinkList = document.querySelectorAll('#article-content a')
            contentLinkList.forEach(link => {
              link.setAttribute('target', '_blank')
            })
          })
        })
    },
    checkGradeIsAvaliable (e, grade, link) {
      /**
       * 點擊任一連結時打 check api，如果未登入就導向登入頁
       * 如果已登入，確認是否可玩該區塊遊戲
       * 如果可以就 push 進去
       * 如果不行就維持在此頁
       */
      const memberToken = localStorage.getItem('memberToken')
      const vm = this
      const api = `${process.env.VUE_APP_APIPATH}/iPlay/member/check`

      if (!memberToken) {
        alert('尚未登入，請先登入之後才能開始遊戲~')
        vm.$router.push('/login')
        return null
      }
      vm.axios.post(api, {
        memberToken,
        token: tokenGenerator()
      }).then(res => {
        const userInfo = res.data.content
        if (userInfo.grade < grade) {
          alert('年級不足，請先玩其他年級的遊戲喔')
        } else {
          vm.$router.push(link)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.marker- {
  &yellow {
    background: yellow;
  }
  &green {
    background: chartreuse;
  }
  &blue {
    background: aqua;
  }
  &pink {
    background: pink;
  }
}
.pen- {
  &green {
    color: green;
  }
  &red {
    color: red;
  }
}
img {
  max-width: 100%;
}
</style>
